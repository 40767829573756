<div class="listItem" (click)="onUserClicked(conversationDetails)">
  <div class="itemThumbnailStyle">
    <!--Avatar-->
    <cometchat-avatar
      [item]="getAvatar(conversationDetails)"
      [userStatus]="conversationDetails.conversationWith.status"
    ></cometchat-avatar>
  </div>
  <div class="itemDetailStyle">
    <div class="itemRowStyle">
      <div class="itemNameStyle">
        <!--Conversation Name-->
        {{ lastMessageName }}
      </div>
      <span class="itemLastMsgTimeStyle">
        {{ lastMessageTimestamp }}
      </span>
    </div>
    <div class="itemRowStyle">
      <div class="itemLastMsgStyle">
        <!--Last Message-->
        {{ lastMessage }}
      </div>
      <!--BadgeCount-->
      <cometchat-badge-count
        *ngIf="conversationDetails.unreadMessageCount > 0"
        [count]="conversationDetails.unreadMessageCount"
      ></cometchat-badge-count>
    </div>
  </div>
</div>
