<div class="sectionStyle">
  <h6 class="sectionHeaderStyle">{{ SHARED_MEDIA }}</h6>
  <div class="sectionContentStyle">
    <div class="mediaBtnStyle">
      <span
        class="buttonStyle"
        (click)="mediaClickHandler(MESSAGE_TYPE_IMAGE)"
        [ngClass]="{
          buttonActiveStyle: imageClick
        }"
        >{{ PHOTOS }}
      </span>

      <span
        class="buttonStyle"
        (click)="mediaClickHandler(MESSAGE_TYPE_VIDEO)"
        [ngClass]="{
          buttonActiveStyle: videoClick
        }"
        >{{ VIDEOS }}
      </span>

      <span
        class="buttonStyle"
        (click)="mediaClickHandler(MESSAGE_TYPE_FILE)"
        [ngClass]="{
          buttonActiveStyle: docsClick
        }"
      >
        {{ DOCUMENT }}
      </span>
    </div>

    <div
      class="mediaItemStyle"
      (scroll)="handleScroll($event)"
      #mediaContainer
      [scrollTop]="scrollVariable"
    >
      <ng-container *ngFor="let msg of messageList">
        <div class="mediaContainer" [ngSwitch]="msg.type" *ngIf="msg.data.url">
          <div class="imageStyle" *ngSwitchCase="MESSAGE_TYPE_IMAGE">
            <div class="itemStylePhotos">
              <img [src]="msg.data.url" loading="lazy" />
            </div>
          </div>

          <div *ngSwitchCase="MESSAGE_TYPE_VIDEO">
            <div class="itemStyleVideo">
              <video [src]="msg.data.url"></video>
            </div>
          </div>
          <div *ngSwitchCase="MESSAGE_TYPE_FILE">
            <div class="itemStyleDocs file">
              <a [href]="msg.data.url" target="_blank">
                {{ msg.data.attachments[0].name }}
              </a>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="checkMediaMessage">
        {{ displayMessage }}
      </div>
    </div>
  </div>
</div>
