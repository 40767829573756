import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from "@angular/router";
import { AuthService } from 'src/app/services/security/auth.service'
import { StorageService } from "src/app/services/security/storage.service"
import { CometChat } from '@cometchat-pro/chat';

@Component({
  selector: 'app-logout',
  template:'&nbsp;',
  styles: ['']
})
export class LogoutComponent implements OnInit, OnDestroy{
  private sub:Subscription;
  constructor(
    private router: Router,
    private authService:AuthService,
    private storageService:StorageService
  ) { }

  ngOnInit() {    
    setTimeout(() => {
      this.storageService.closeSession();
    });    
    
    this.sub=this.authService.logout().subscribe(
      res=>{                   
        CometChat.logout().then(response=>{
          console.log("CometChat Logout completed successfully");
        },error=>{
          console.log(error);
        });
        this.router.navigate(['/login']);
      },
      err=>{
        console.log(err);
        this.router.navigate(['/login']);
      }
    )
  }

  ngOnDestroy() {
    if(this.sub){
      this.sub.unsubscribe();
    }
  }
}
