import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor() { }

  public validatePassword(password:string):string{
    if(password){
      if(password.length>=8){
        let regex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_+~:;?><,.=]).{8,}');
        if(regex.test(password)){
          return null;
        }else{
          return "The password needs to have at least 1 upper case, 1 lower case, 1 number and 1 special character: !@#$%^&*_+~:;?><,.=";  
        }
      }else{
        return "The password length must be at least 8 characters";
      }
    }else{
      return "Please enter the password";
    }    
  }

  public generatePassword(len:number):string{
    var length = (len)?(len):(8);
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper 
    var numeric = '0123456789';
    var punctuation = '!@#$%^&*_+~:;?><,.=';
    var password = "";
    var character = "";
    var crunch = true;
    while( password.length<length ) {
        let entity1 = Math.ceil(string.length * Math.random()*Math.random());
        let entity2 = Math.ceil(numeric.length * Math.random()*Math.random());
        let entity3 = Math.ceil(punctuation.length * Math.random()*Math.random());
        let hold = string.charAt( entity1 );
        hold = (password.length%2==0)?(hold.toUpperCase()):(hold);
        character += hold;
        character += numeric.charAt( entity2 );
        character += punctuation.charAt( entity3 );
        password = character;
    }
    password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
    return password.substr(0,len);
  }
}
