<div [ngSwitch]="tickStatus">
  <div
    *ngSwitchCase="SENT"
    class="recieptContainer"
    [ngClass]="{
      recieptContainerSecondaryStyle: displayReadReciept === false
    }"
  >
    <span
      class="msgTimestampStyle"
      [ngClass]="{
        msgTimestampSecondaryStyle: displayReadReciept === false
      }"
    >
      {{ time | date: "shortTime" }}
    </span>
    <span *ngIf="displayReadReciept" class="tick sent">&nbsp;</span>
  </div>
  <div
    *ngSwitchCase="DELIVERED"
    class="recieptContainer"
    [ngClass]="{
      recieptContainerSecondaryStyle: displayReadReciept === false
    }"
  >
    <span
      class="msgTimestampStyle"
      [ngClass]="{
        msgTimestampSecondaryStyle: displayReadReciept === false
      }"
    >
      {{ time | date: "shortTime" }}
    </span>
    <span *ngIf="displayReadReciept" class="tick deliv">&nbsp;</span>
  </div>
  <div
    *ngSwitchCase="READ"
    class="recieptContainer"
    [ngClass]="{
      recieptContainerSecondaryStyle: displayReadReciept === false
    }"
  >
    <span
      class="msgTimestampStyle"
      [ngClass]="{
        msgTimestampSecondaryStyle: displayReadReciept === false
      }"
    >
      {{ time | date: "shortTime" }}
    </span>
    <span *ngIf="displayReadReciept" class="tick read">&nbsp;</span>
  </div>
</div>
