import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadService {

  @Output() dataEvent = new EventEmitter();
  breads: any = [];

  makeBread(bread){
    this.breads=bread;
    this.dataEvent.emit(this.breads);
  }
}
