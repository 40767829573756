import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http'
import { User } from 'src/app/models/user';

const headToken =new HttpHeaders({
  "appId":environment.COMETCHAT_API_ID,
  "apiKey":environment.COMETCHAT_API_REST_KEY,
  "content-type":"application/json",
  "accept":"application/json"
});

@Injectable({
  providedIn: 'root'
})
export class CometchatService {

  constructor(private http: HttpClient) {    
  }

  createUserChat(data:User){
    this.reloadUserFields(data);
    return this.createUser(data.username, data.fullname);
  }
  updateUserChat(username:string, data:User){
    data.username=username;
    this.reloadUserFields(data);
    return this.updateUser(data.username, data.fullname);
  }

  reloadUserFields(data:User){
    data.fullname=data.firstname+" "+data.lastname;
    if(data.middlename!="" && data.middlename!="null" && data.middlename!=null){
      data.fullname=data.firstname+" "+data.middlename+" "+data.lastname;
    }
    /*-
    data.avatar="";
    this.verifyImage(data.username).subscribe(
      res=>{
        let aux=res as Message
        console.log(res);
        if(aux.message=="true")
        {
          console.log("Entro aqui");
          data.avatar=environment.DIR_URL+"profile/"+data.username+".jpg";
        }
      }, err=>{
        console.log(err);
      }
    )
    */
  }

  createUser(_username, _name){    
    let data = {"uid":_username, "name":_name};
    return this.http.post(environment.COMETCHAT_API_REST_URL+'/users', data, {headers:headToken} );
  }

  updateUser(_username, _name){
    var data = {"name":_name};
    return this.http.put(environment.COMETCHAT_API_REST_URL+'/users/'+_username, data, {headers:headToken} );
  }

  inactiveUser(uid){
    return this.http.delete(environment.COMETCHAT_API_REST_URL+'/users/'+uid, {headers:headToken});
  }

  activeUser(uid){
    var aux=[];
    aux.push(uid);
    var data : any;
    data = {"uidsToActivate":aux};
    return this.http.put(environment.COMETCHAT_API_REST_URL+'/users', data, {headers:headToken} );
  }

  tokenCreate(uid){
    return this.http.post(environment.COMETCHAT_API_REST_URL+'/users/'+uid+'/auth_tokens', null, {headers:headToken, responseType: 'text'});    
  }

  tokenFlush(uid){
    return this.http.delete(environment.COMETCHAT_API_REST_URL+'/users/'+uid+'/auth_tokens', {headers:headToken});    
  }

  verifyImage(uid){
    return this.http.get(environment.API_URL+'/auth/verify/'+uid);
  }

  public listChatUsers(){
    return this.http.get(environment.API_URL+'/auth/chatusers/');
  }
}
