import { Injectable } from '@angular/core';
import { DataAccess} from 'src/app/temp/models/menu-data'
import { MenuAccess } from 'src/app/temp/models/menu'

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() {}

  private getAccessByProfile(profile:number):MenuAccess[]{
    return DataAccess.filter(function (ele) {
      return (ele.profile === profile);
    });
  }

  public routeValidation(profile, route:string){
    var menus=this.getAccessByProfile(parseInt(profile));
    for(var access of menus){
      if(access.link.trim()==route.trim()){
        return true;
      }else{
        if(route.startsWith(access.link)){
          var aux=parseInt(route.replace(access.link, "").replace("/",""));
          if(Number.isInteger(aux)){
            return true;
          }
          return false;
        }
      }
    }
    return false;
  }

}
