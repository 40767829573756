<div class="messageContainerStyle" *ngIf="isPollExtensionEnabled">
  <cometchat-message-actions
    class="tool"
    [ngClass]="{
      toolGroup: messageDetails?.receiverType == GROUP
    }"
    [messageDetails]="messageDetails"
    (actionGenerated)="actionHandler($event)"
    *ngIf="showToolTip"
  ></cometchat-message-actions>
  <div class="messageWrapperStyle">
    <!-- Avatar for group -->
    <div
      class="messageThumbnailStyle"
      *ngIf="messageDetails?.receiverType == GROUP"
    >
      <cometchat-avatar
        [item]="messageDetails?.sender"
        [enableUserStatus]="false"
        class="avatarStyle"
      ></cometchat-avatar>
    </div>
    <div class="messageDetailStyle">
      <!-- Name -->
      <div
        class="nameWrapperStyle"
        *ngIf="messageDetails?.receiverType == GROUP"
      >
        <span class="nameStyle">
          {{ messageDetails?.sender?.name }}
        </span>
      </div>
      <div class="messageTxtContainerStyle">
        <div class="messageTxtWrapperStyle">
          <p class="pollQuestionStyle">{{ pollExtensionData?.question }}</p>
          <ul class="pollAnswerStyle">
            <!-- Options -->
            <li
              *ngFor="let option of pollOptions"
              (click)="answerPollQuestion(option)"
            >
              <div
                class="pollPercentStyle"
                [ngStyle]="{ width: option?.percent }"
              ></div>
              <div
                [ngClass]="{
                  answerWrapperStyle: !option?.selectedByLoggedInUser,
                  answerCheckedWrapperStyle: option?.selectedByLoggedInUser
                }"
              >
                <span> {{ option?.percent }}</span>
                <p>{{ option?.text }}</p>
              </div>
            </li>

            <!-- Options -->
          </ul>
          <p
            class="pollTotalStyle"
            *ngIf="pollExtensionData?.results?.total === 1"
          >
            {{ pollExtensionData?.results?.total }} vote
          </p>
          <p
            class="pollTotalStyle"
            *ngIf="pollExtensionData?.results?.total !== 1"
          >
            {{ pollExtensionData?.results?.total }} votes
          </p>
        </div>
      </div>
      <cometchat-message-reactions
        *ngIf="checkReaction"
        [messageDetails]="messageDetails"
        [loggedInUser]="loggedInUser"
        (actionGenerated)="actionHandler($event)"
      ></cometchat-message-reactions>
      <div class="messageInfoWrapperStyle">
        <cometchat-read-receipt
          [messageDetails]="messageDetails"
          [displayReadReciept]="false"
        ></cometchat-read-receipt>
        <!--ReplyCount-->
        <cometchat-threaded-message-reply-count
          *ngIf="showReplyCount"
          [messageDetails]="messageDetails"
          (actionGenerated)="actionHandler($event)"
        >
        </cometchat-threaded-message-reply-count>
      </div>
    </div>
  </div>
</div>
