<div class="messageContainerStyle" *ngIf="loggedInUserDeletedThisMessage">
  <div class="messageWrapperStyle">
    <div class="messageTxtWrapperStyle">
      <p class="messageTxtStyle">{{ YOU_DELETED_THIS_MESSAGE }}</p>
    </div>
    <div class="messageInfoWrapperStyle">
      <span class="messageTimeStampStyle">
        {{ time | date: "shortTime" }}
      </span>
    </div>
  </div>
</div>

<!-- Received a Deleted Message Buubble -->
<div
  class="messageContainerStyle deletedMessageReceivedStyle"
  *ngIf="!loggedInUserDeletedThisMessage"
>
  <div class="deletedMessageWrapperStyle">
    <div
      class="messageThumbnailStyle"
      *ngIf="messageDetails?.receiverType == GROUP"
    >
      <cometchat-avatar
        [item]="messageDetails?.sender"
        [enableUserStatus]="false"
        class="avatarStyle"
      ></cometchat-avatar>
    </div>
    <div class="messageDetailStyle">
      <div
        class="nameWrapperStyle"
        *ngIf="messageDetails?.receiverType == GROUP"
      >
        <span class="nameStyle">
          {{ messageDetails?.sender?.name }}
        </span>
      </div>
      <div class="messageTxtWrapperStyle deletedMessageReceivedStyle">
        <p class="messageTxtStyle">{{ THIS_MESSAGE_DELETED }}</p>
      </div>
      <div class="messageInfoWrapperStyle deletedMessageReceivedStyle">
        <span class="messageTimeStampStyle">
          {{ time | date: "shortTime" }}
        </span>
      </div>
    </div>
  </div>
</div>
<!-- Received a Deleted Message Buubble -->
