<div class="messageContainerStyle" *ngIf="isPollExtensionEnabled">
  <cometchat-message-actions
    class="tool"
    *ngIf="showToolTip"
    [messageDetails]="messageDetails"
    (actionGenerated)="actionHandler($event)"
  ></cometchat-message-actions>
  <div class="messageWrapperStyle">
    <div class="messageTxtWrapperStyle">
      <p class="pollQuestionStyle">{{ pollExtensionData?.question }}</p>
      <ul class="pollAnswerStyle">
        <!-- Options -->
        <li *ngFor="let option of pollOptions">
          <div
            class="pollPercentStyle"
            [ngStyle]="{ width: option?.percent }"
          ></div>
          <div class="answerWrapperStyle">
            <span>{{ option?.percent }}</span>
            <p>{{ option?.text }}</p>
          </div>
        </li>
        <!-- Options -->
      </ul>
      <p class="pollTotalStyle" *ngIf="pollExtensionData?.results?.total === 1">
        {{ pollExtensionData?.results?.total }} vote
      </p>
      <p class="pollTotalStyle" *ngIf="pollExtensionData?.results?.total !== 1">
        {{ pollExtensionData?.results?.total }} votes
      </p>
    </div>
  </div>
  <cometchat-message-reactions
    *ngIf="checkReaction"
    [messageDetails]="messageDetails"
    [loggedInUser]="loggedInUser"
    (actionGenerated)="actionHandler($event)"
  ></cometchat-message-reactions>
  <div class="messageInfoWrapperStyle">
    <cometchat-threaded-message-reply-count
      *ngIf="showReplyCount"
      [messageDetails]="messageDetails"
      (actionGenerated)="actionHandler($event)"
    >
    </cometchat-threaded-message-reply-count>
    <cometchat-read-receipt
      [messageDetails]="messageDetails"
    ></cometchat-read-receipt>
  </div>
</div>
