<div
  class="regularReaction"
  [ngClass]="{
    regularReactionSecondaryStyle:
      messageDetails.sender.uid === loggedInUser.uid
  }"
>
  <div
    class="messageReactionStyle"
    *ngIf="
      messageReactions?.length > 0 &&
      messageDetails.sender.uid === loggedInUser.uid
    "
  >
    <button class="emojiButtonStyle" (click)="sendReaction()">
      <img [src]="reactionIcon" class="regularReactionIcon" />
    </button>
  </div>
  <div
    *ngFor="let reaction of messageReactions"
    class="messageReactionsStyle"
    (click)="reactToMessages(reaction)"
    [title]="reaction?.reactionTitle"
    [ngClass]="{
      messageReactionsSecondaryStyle: reaction?.showBlueOutline,
      messageReactionsHoverStyle: !reaction?.showBlueOutline
    }"
  >
    <ngx-emoji [emoji]="reaction?.reactionName" size="16"></ngx-emoji>
    <span class="reactionCountStyle">
      {{ reaction?.reactionCount }}
    </span>
  </div>

  <div
    class="messageReactionStyle"
    *ngIf="
      messageReactions?.length > 0 &&
      messageDetails.sender.uid !== loggedInUser.uid
    "
  >
    <button class="emojiButtonStyle" (click)="sendReaction()">
      <img [src]="reactionIcon" class="regularReactionIcon" />
    </button>
  </div>
</div>
