<div class="chatHeaderStyle">
  <div class="chatDetailStyle">
    <!-- SideBar menu icon when screen size goes to mobile -->
    <div class="chatSideBarBtnStyle" (click)="closeChatWindow()"></div>
    <div class="chatThumbnailStyle">
      <!-- Pass The emitted User to The AVATAR below -->
      <cometchat-avatar
        [item]="item"
        [userStatus]="item?.status"
      ></cometchat-avatar>
    </div>
    <div class="chatUserStyle">
      <!-- Add Tool Tip functions here -->
      <h6 class="chatNameStyle">{{ item.name }}</h6>
      <div class="chatStatusStyle typing" *ngIf="isTyping">{{ status }}</div>
      <!-- Add Mouse Event to status -->
      <div *ngIf="item.status === ONLINE && !isTyping">
        <span class="chatStatusStyle">{{ item.status }}</span>
      </div>

      <div *ngIf="item.status === OFFLINE">
        <span class="chatStatusStyle lastActive">{{
          getLastActiveDate(item.lastActiveAt)
        }}</span>
      </div>

      <div *ngIf="type === GROUP && !isTyping">
        <span class="chatStatusStyle groupMemberCountStatusStyle">{{
          status
        }}</span>
      </div>
    </div>
  </div>
  <div class="chatOptionWrapStyle">
    <!-- add action generated to call -->
    <div
      class="chatOptionStyle callOption"
      *ngIf="checkNotBlocked"
      (click)="audioCall()"
    ></div>
    <div
      class="chatOptionStyle videoCallOption"
      *ngIf="checkNotBlocked"
      (click)="videoCall()"
    ></div>
    <div class="chatOptionStyle detailsOption" (click)="openUserDetail()"></div>
  </div>
</div>
