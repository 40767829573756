import { Component, OnInit } from '@angular/core';
import { BreadService } from 'src/app/services/util/bread.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  constructor(
    private breadService:BreadService
  ) {
    var breads = [
      {label:'Chat', link: ''}
    ];
    setTimeout(() => {
      this.breadService.makeBread(breads);
    });
  }

  ngOnInit() {
  }

}
