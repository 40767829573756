<div id="sidebar">
    <ul>
        <li id="sidebar-main">
            <a> 
                <i class="sidebar-icon icn-menu"></i>
                <!--
                <i class="sidebar-icon icn-spartan"></i>
                <div>Spartan Careshield</div>
                <div>{{profileName}}</div>
                -->
            </a>
        </li>
        <li *ngFor="let menu of menus"  (mouseover)="openMenu(menu.id)" (mouseout)="closeMenus()">
            <a [routerLink]="menu.link" [name]="'mnu'+menu.id" [ngClass]="{'active':menu.selected}" (click)="clickMenu(menu)">
                <i [ngClass]="menu.icon"></i>
                <div>{{menu.label}}</div>
                <div class="itembar" *ngIf="menu.complex" (mouseout)="closeMenus()">
                    <ul [ngClass]="{'itemHide':!menu.open}">
                        <li *ngFor="let item of menu.children" (click)="clickItem(item)">
                            <span>{{item.label}}</span>
                        </li>
                    </ul>
                </div>
            </a>
        </li>
    </ul>
</div>    