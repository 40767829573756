<div class="listStyle">
  <div *ngIf="displayConversationList" class="listDisplayStyle">
    <cometchat-conversation-list
      (onUserClick)="userClicked($event)"
      [item]="item"
      [lastMessage]="lastMessage"
      [type]="type"
    ></cometchat-conversation-list>
  </div>

  <div *ngIf="displayGroupList" class="listDisplayStyle">
    <cometchat-group-list
      [enableSelectedGroupStyling]="true"
      [groupToUpdate]="groupToUpdate"
      [groupToLeave]="groupToLeave"
      [groupToDelete]="groupToDelete"
      (onGroupClick)="groupClicked($event)"
    ></cometchat-group-list>
  </div>

  <div *ngIf="displayUserList" class="listDisplayStyle">
    <cometchat-user-list
      [item]="curentItem"
      (onUserClick)="userClicked($event)"
    ></cometchat-user-list>
  </div>
  
  <!-- UserInfo (Settings) Disabled
  <div class="userInfoScreenStyle" *ngIf="displayUserInfoScreen">
    <cometchat-user-profile></cometchat-user-profile>
  </div>
  -->
</div>

<div class="footerStyle">
  <div class="navbarStyle">
    <!--ConversationList-->
    <div class="itemStyle" (click)="openConversationList()">
      <div
        class="itemLinkStyle Chats"
        [ngClass]="{
          ChatsActiveStyle: displayConversationList
        }"
      ></div>
    </div>
    <!--ConversationList Ends-->

    <!--UserList-->
    <div class="itemStyle" (click)="openUserList()">
      <div
        class="itemLinkStyle Contacts"
        [ngClass]="{
          ContactsActiveStyle: displayUserList
        }"
      ></div>
    </div>
    <!--UserList Ends-->

    <!--GroupList-->
    <div class="itemStyle" (click)="openGroupList()">
      <div
        class="itemLinkStyle Groups"
        [ngClass]="{
          GroupsActiveStyle: displayGroupList
        }"
      ></div>
    </div>
    <!--GroupList Ends-->

    <!-- UserInfo (Settings) Disabled
    <div class="itemStyle" (click)="openUserInfoScreen()">
      <div
        class="itemLinkStyle Info"
        [ngClass]="{
          InfoActiveStyle: displayUserInfoScreen
        }"
      ></div>
    </div>
    -->
  </div>
</div>
