<div
  class="incomingCallWrapperStyle"
  *ngIf="incomingCall !== null"
  [@slideInOut]
>
  <div class="callContainerStyle">
    <div class="headerWrapperStyle">
      <div class="callDetailStyle">
        <div class="nameStyle">
          <!--name-->
          {{ name }}
        </div>
        <div class="callTypeStyle">
          <!--call type-->
          <div *ngIf="incomingCall.type === CALL_TYPE_AUDIO">
            <span class="audioCall"></span>
            <span>{{ INCOMING_AUDIO_CALL }}</span>
          </div>
          <div *ngIf="incomingCall.type === CALL_TYPE_VIDEO">
            <span class="videoCall"></span>
            <span>{{ INCOMING_VIDEO_CALL }}</span>
          </div>
        </div>
      </div>
      <div class="thumbnailStyle">
        <!--avatar-->
        <cometchat-avatar
          [item]="user"
          [enableUserStatus]="false"
        ></cometchat-avatar>
      </div>
    </div>
    <div class="headerButtonStyle">
      <button class="ButtonStyle Decline" (click)="rejectCall()">
        {{ DECLINE }}
      </button>
      <button class="ButtonStyle Accept" (click)="acceptCall()">
        {{ ACCEPT }}
      </button>
    </div>
  </div>
</div>
