import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateRouteGuard } from './guard/can-activate-route.guard';
import { ChatComponent } from './start/chat/chat.component';
import { ForgotComponent } from './start/forgot/forgot.component';
import { HomeComponent } from './start/home/home.component'
import { LogoutComponent } from './start/home/logout.component'
import { LoginComponent } from './start/login/login.component'
import { ResetPassComponent } from './start/reset-pass/reset-pass.component';
import { ResetComponent } from './start/reset/reset.component';

const routes: Routes = [
  {
    path: 'patient', loadChildren: () =>
      import('./pages/patient/patient.module').then(m => m.PatientModule)
  },
  {
    path: 'provider', loadChildren: () =>
      import('./pages/provider/provider.module').then(m => m.ProviderModule)
  },
  {
    path: 'staff', loadChildren: () =>
      import('./pages/staff/staff.module').then(m => m.StaffModule)
  },
  {
    path: 'facility_user', loadChildren: () =>
      import('./pages/facusers/facusers.module').then(m => m.FacusersModule)
  },
  {
    path: 'facility', loadChildren: () =>
      import('./pages/facility/facility.module').then(m => m.FacilityModule)
  },
  {
    path: 'visit', loadChildren: () =>
      import('./pages/visit/visit.module').then(m => m.VisitModule)
  },
  {
    path: 'schedule', loadChildren: () =>
      import('./pages/schedule/schedule.module').then(m => m.ScheduleModule)
  },
  {
    path: 'settings', loadChildren: () =>
      import('./pages/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'report', loadChildren: () =>
      import('./pages/report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'log', loadChildren: () =>
      import('./pages/log/log.module').then(m => m.LogModule)
  },
  { path: 'home', component: HomeComponent},
  { path: 'chat', component: ChatComponent, canActivate:[CanActivateRouteGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'loginpass', component: ResetPassComponent},
  { path: 'reset/:token', component: ResetComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
