<div class="reactionContainerStyle">
  <span
    class="reactionEmojiStyle"
    #emoji
    [@FadeInFadeOut]="checkAnimatedState"
  ></span>
  <!-- </div> -->
  <span
    class="reactionEmojiStyle"
    #emoji
    [@FadeInFadeOut]="checkAnimatedState"
  ></span>
  <span
    class="reactionEmojiStyle"
    #emoji
    [@FadeInFadeOut]="checkAnimatedState"
  ></span>
  <span
    class="reactionEmojiStyle"
    #emoji
    [@FadeInFadeOut]="checkAnimatedState"
  ></span>
  <span
    class="reactionEmojiStyle"
    #emoji
    [@FadeInFadeOut]="checkAnimatedState"
  ></span>
</div>
