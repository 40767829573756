import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/services/security/auth-interceptor'
import { TempModule } from './temp/temp.module';
import { HomeComponent } from './start/home/home.component';
import { LogoutComponent } from './start/home/logout.component';
import { LoginComponent } from './start/login/login.component';
import { ResetComponent } from './start/reset/reset.component';
import { ForgotComponent } from './start/forgot/forgot.component';
import { NgxSummernoteModule } from 'ngx-summernote';

//Primefaces
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { UsernameUniqueDirective } from './validations/username-unique.directive';
import { ResetPassComponent } from './start/reset-pass/reset-pass.component';
import { ChatComponent } from './start/chat/chat.component';

//CometChat
import { CometChatUI } from "./cometchat-pro-angular/CometChatWorkspace/projects/angular-chat-ui-kit/src/components/CometChatUI/CometChat-Ui/cometchat-ui.module";

//import { AngularChatUiKitModule } from '../cometchat-pro-angular-ui-kit-master/CometChatWorkspace/projects/angular-chat-ui-kit/src/components/angular-chat-ui-kit.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LogoutComponent,
    LoginComponent,
    UsernameUniqueDirective,
    ResetComponent,
    ForgotComponent,
    ResetPassComponent,
    ChatComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TempModule,
    ToastModule,
    ButtonModule,
    CometChatUI,
    NgxSummernoteModule.forRoot() 
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
