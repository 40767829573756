<div
  class="listItem"
  [ngClass]="{
    selectedGroupStyle: group?.guid === selectedGroup?.guid
  }"
  (click)="groupClicked(group)"
>
  <div class="itemThumbnailStyle">
    <cometchat-avatar [item]="group"></cometchat-avatar>
  </div>
  <div class="itemDetailStyle">
    <div class="itemNameWrapperStyle">
      <span class="listItemName">{{ group.name }}</span>
      <div [ngSwitch]="group?.type">
        <div *ngSwitchCase="'private'">
          <span class="shieldIcon"></span>
        </div>
        <div *ngSwitchCase="'password'">
          <span class="lockIcon"></span>
        </div>
      </div>
    </div>
    <div class="itemDescStyle" *ngIf="group.membersCount == 1">
      {{ group?.membersCount }} member
    </div>
    <div class="itemDescStyle" *ngIf="group.membersCount > 1">
      {{ group?.membersCount }} members
    </div>
  </div>
</div>
