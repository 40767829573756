<div class="groupWrapperStyle">
  <div class="groupHeaderStyle">
    <!-- enableCloseMenu -->

    <h4 class="groupHeaderTitleStyle">{{ GROUPS }}</h4>
    <div class="groupAddStyle" (click)="toggleCreateGroupView()"></div>
  </div>
  <div class="groupSearchStyle">
    <input
      class="groupSearchInputStyle"
      type="text"
      autoComplete="off"
      [placeholder]="SEARCH"
      (keyup)="searchGroup($event)"
    />
  </div>
  <div class="groupMsgStyle">
    <p class="groupMsgTxtStyle">{{ decoratorMessage }}</p>
  </div>
  <div class="groupListStyle" (scroll)="handleScroll($event)">
    <div *ngFor="let group of groupList">
      <cometchat-group-list-item
        [group]="group"
        [selectedGroup]="this.selectedGroup"
        (onGroupClick)="groupClicked($event)"
      ></cometchat-group-list-item>
    </div>
  </div>
  <!-- create group component -->
  <cometchat-create-group
    *ngIf="openCreateGroupView"
    (actionGenerated)="actionHandler($event)"
  ></cometchat-create-group>
  <!-- create group component -->
</div>
