import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { BreadService } from 'src/app/services/util/bread.service'
import { StorageService } from 'src/app/services/security/storage.service'
import { AuthService } from 'src/app/services/security/auth.service';
import { EnumGeneric, EnumProfiles } from 'src/app/models/enums';
import { FormBuilder, Validators } from '@angular/forms';
import { PasswordService } from 'src/app/services/util/password.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

  lastAccess;
  breadList:any[];
  nameUser:string;
  nameProfile:string;
  nameAccess:string;
  private sub:Subscription;
  constructor(
    private breadService:BreadService,
    private passwordService:PasswordService,
    private storageService:StorageService,
    private messageService: MessageService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router:Router
  ){ 
    this.nameProfile="";
    this.nameAccess=(new Date()).toDateString();
  }

  ngOnInit() {
    this.nameProfile=this.profileName(this.storageService.getSessionAttribute(EnumGeneric.SESSION_PROFILE))
    this.nameUser=this.storageService.getSessionAttribute(EnumGeneric.SESSION_FULLNAME)
    if(this.storageService.getSessionAttribute(EnumGeneric.SESSION_LASTACCESS)!=null && this.storageService.getSessionAttribute(EnumGeneric.SESSION_LASTACCESS)!="null"){
      this.nameAccess=this.storageService.getSessionAttribute(EnumGeneric.SESSION_LASTACCESS);
    }
    this.breadService.dataEvent.subscribe(breads=>{
      this.breadList=breads;
    })
  }
  
  profileName(profile){
    switch(parseInt(profile)){
      case EnumProfiles.ADMIN:
        return "Administrator";
      case EnumProfiles.STAFF:
        return "Staff User";
      case EnumProfiles.PROVIDER:
        return "Provider"; 
      case EnumProfiles.PATIENT:
        return "Patient";
      case EnumProfiles.FACILITY:
        return "Facility";
      default:
        return "None";
    }
  }

  resetForm=this.formBuilder.group({
    oldPass: ['', Validators.required],
    newPass: ['', Validators.required],
    repPass: ['', Validators.required],
  })
  errorForm=false;
  displayChangePassword:boolean=false;
  openChangePassword() {
    this.resetForm.reset();
    this.displayChangePassword=true;
  }

  closeChangePassword(){
    this.displayChangePassword=false;
  }

  resetPassword(){
    this.errorForm=false;
    if(this.resetForm.valid){
      if(this.resetForm.controls['newPass'].value==this.resetForm.controls['repPass'].value){
        let pass=this.resetForm.controls['newPass'].value;
        let message=this.passwordService.validatePassword(pass);
        if(message){
          this.resetForm.controls['newPass'].setValue('');
          this.resetForm.controls['repPass'].setValue('');
          this.messageService.add({severity:'error', summary:'Error', detail:message});
        }else{
          this.sub=this.authService.updatePassword(this.resetForm.value).subscribe(
            res => {
              this.messageService.add({severity:'info', summary:'Success', detail:'Password Updated'});
              this.displayChangePassword=false;
            },
            err =>{
              console.error(err)
              this.messageService.add({severity:'error', summary:'Error', detail:err.error.message});
              this.resetForm.reset();
            } 
          )
        }
      }else{
        this.errorForm=true;
      }
    }else{
      this.errorForm=true;
    }
  }

  exit(){
    this.router.navigate(['/logout']);
  }

  ngOnDestroy() {
    if(this.sub){
      this.sub.unsubscribe();
    }
  }
}
