<div>
  <cometchat-backdrop></cometchat-backdrop>
  <div class="modalWrapperStyle">
    <span class="modalCloseStyle" (click)="closeViewMemberModal()"></span>
    <div class="modalBodyStyle">
      <table class="modalTableStyle">
        <caption class="tableCaptionStyle">
          {{
            GROUP_MEMBERS
          }}
        </caption>
        <thead>
          <tr>
            <th>{{ NAME }}</th>
            <th class="scopeColumnStyle">{{ SCOPE }}</th>

            <!-- EDIT ACCESS -->
            <th class="actionColumnStyle">
              <div *ngIf="item?.scope !== PARTICIPANT">{{ BAN }}</div>
            </th>
            <th class="actionColumnStyle">
              <div *ngIf="item?.scope !== PARTICIPANT">{{ KICK }}</div>
            </th>
            <!-- EDIT ACCESS -->
          </tr>
        </thead>
        <tbody class="tableBodyStyle">
          <cometchat-view-group-member-list-item
            *ngFor="let member of memberList"
            [member]="member"
            [item]="item"
            [type]="type"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-view-group-member-list-item>
        </tbody>
      </table>
    </div>
  </div>
</div>
