import { Component, OnInit } from '@angular/core';
import { EnumProfiles, EnumRoutes } from 'src/app/models/enums';
import { Router } from "@angular/router";
import { StorageService } from "src/app/services/security/storage.service"
import { EnumGeneric } from 'src/app/models/enums'
@Component({
  selector: 'app-home',
  template:'&nbsp;',
  styles: ['']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private storage:StorageService
  ) { }

  ngOnInit() {    
    var profile=this.storage.getSessionAttribute(EnumGeneric.SESSION_PROFILE);
    if(!profile){
      profile="0";
    } 
    this.redirectHome(parseInt(profile));
  }

  redirectHome(profile:number){        
    switch(profile){
      case EnumProfiles.ADMIN:
        this.router.navigate([EnumRoutes.PATIENT_LIST_FULL]);  
        break;
      case EnumProfiles.STAFF:
        this.router.navigate([EnumRoutes.SCHEDULE_STAFF_WEEK]);
        break;
      case EnumProfiles.PROVIDER:
        this.router.navigate([EnumRoutes.SCHEDULE_PROVIDER_WEEK]);  
        break;        
      case EnumProfiles.FACILITY:
        this.router.navigate([EnumRoutes.SCHEDULE_FACILITY_WEEK]);  
        break;                
      default:        
        this.router.navigate([EnumRoutes.LOGIN]);  
    }
  }
}
