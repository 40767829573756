import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CometChat } from '@cometchat-pro/chat';

const chatSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(environment.COMETCHAT_API_REGION)
  .build();

if (environment.production) {
  enableProdMode();
}

CometChat.init(environment.COMETCHAT_API_ID, chatSetting).then(
  () => {
    console.log("CometChat Initialization completed successfully");
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  },
  (error) => {
    console.log("CometChat Initialization failed with error:", error);
  }
);

//platformBrowserDynamic().bootstrapModule(AppModule)
//  .catch(err => console.error(err));
