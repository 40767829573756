<div>
  <cometchat-backdrop></cometchat-backdrop>
  <div class="modalWrapperStyle">
    <span class="modalCloseStyle" (click)="closeAddMembersView()"></span>
    <div class="modalBodyStyle">
      <table class="modalTableStyle">
        <caption class="tableCaptionStyle">
          {{
            USERS
          }}
        </caption>
        <caption class="tableSearchStyle">
          <input
            class="searchInputStyle"
            type="text"
            autoComplete="off"
            [placeholder]="SEARCH"
            (keyup)="searchUsers($event)"
          />
        </caption>

        <caption class="contactMsgStyle" *ngIf="decoratorMessage !== ''">
          <p class="contactMsgTxtStyle">{{ decoratorMessage }}</p>
        </caption>

        <tbody class="tableBodyStyle" (scroll)="handleScroll($event)">
          <cometchat-add-group-member-list-item
            *ngFor="let user of filteredList"
            [item]="item"
            [type]="type"
            [user]="user"
            [members]="memberList"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-add-group-member-list-item>
        </tbody>

        <tfoot class="tableFootStyle">
          <tr>
            <td colSpan="2" (click)="updateMembers()">
              <button>{{ addBtnText }}</button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
