import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Validators, FormGroup, FormBuilder} from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/security/auth.service"
import { MessageService } from 'primeng/api';
import { PasswordService } from 'src/app/services/util/password.service';
import { StorageService } from "src/app/services/security/storage.service"

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.css']
})
export class ResetPassComponent implements OnInit, OnDestroy {

  isLoading=false;
  resetForm: FormGroup;
  errorForm=false;
  private sub:Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService:AuthService,
    private passwordService:PasswordService,
    private messageService:MessageService,
    private storageService:StorageService
  ) { 
    this.resetForm = this.formBuilder.group({
      password: ['', Validators.required],
      repassword: ['', Validators.required]
    })
  }

  ngOnInit() {
  }

  public resetPassword(): void {
    this.isLoading=false;
    this.errorForm=false;
    if(this.resetForm.valid){
      if(this.resetForm.controls['password'].value==this.resetForm.controls['repassword'].value){
        let message=this.passwordService.validatePassword(this.resetForm.controls['password'].value);
        if(message){
          this.resetForm.reset();
          this.messageService.add({severity:'error', summary:'Error', detail:message});
        }else{
          this.isLoading=true;      
          this.sub=this.authService.changePassword(this.resetForm.value).subscribe(
            async data => {
              this.isLoading=false;
              this.messageService.add({severity:'info', summary:'Success Message', detail:'The password is updated.'});              
              this.router.navigate(['logout']);
            },
            err => {
              console.log(err);
              this.messageService.add({severity:'error', summary:'Error', detail:err.error.message});
              this.resetForm.reset();
              this.isLoading=false;
            }
          );
        }
      }else{
        this.messageService.add({severity:'error', summary:'Error', detail:"The password and re-type password doesn't match"});
        this.resetForm.reset();
      }
    }else{
      this.errorForm=true;
    }
  }

  ngOnDestroy() {
    if(this.sub){
      this.sub.unsubscribe();
    }
  }
}
