<nav id="navid">
    <span class="navbar-brand" href="#">
        <i class="icn-spartan"></i>
        <span>Spartan CareShield
            <div>{{nameProfile}}</div>
        </span>
        <span _ngcontent-drt-c2="" style="float: right;top: 27px;position: absolute;right: 36px;">
            <a _ngcontent-drt-c2="" href="https://newaccount1635263336639.freshdesk.com/support/home" target="_blank">
            <button _ngcontent-drt-c2="" label="Request Support" pbutton="" type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
            <span class="ui-button-text ui-clickable">Request Support</span>
            </button>
            </a>
        </span>
    </span> 
</nav>
<div id="breadid">
    <div style="float:left;">
    <ul>
        <li>
            <a>
                <i class="pi pi-home"></i>
            </a>
        </li>
        <li *ngFor="let bread of breadList">
            <a *ngIf="bread.link" [routerLink]="bread.link">
                <i class="pi pi-angle-right"></i>
                <span class="bread-label">{{bread.label}}</span>
            </a>
            <a *ngIf="bread.link==''">
                <i class="pi pi-angle-right"></i>
                <span class="bread-label">{{bread.label}}</span>
            </a>
        </li>        
    </ul>
    </div>
    <div style="float:right;">        
        <ul style="margin-top:-5px;">
            <li class="nav-link">
                Last Access: {{nameAccess |  date:'MM/dd/yyyy - HH:mm'}}
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{nameUser}}
                </a>
                <div class="nav-down dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style="font-size:12px;">
                    <a class="dropdown-item" (click)="openChangePassword()" ><i class="pi pi-key" style="padding:5px !important;"></i>Update Password</a>
                    <div class="dropdown-divider"></div>
                    <a (click)="exit()" class="dropdown-item"><i class="pi pi-sign-out" style="padding:5px !important;"></i>Log out</a>
                </div>
            </li>
        </ul>
    </div>
</div>

<p-dialog [(visible)]="displayChangePassword" [modal]="true" [contentStyle]="{'width':'320px'}">    
    <p-header>Reset Password</p-header>    
    <form (submit)="resetPassword()" [formGroup]="resetForm">
    <div class="p-grid">
        <div class="p-col-12 zeta-input">
            <span>Old Password</span>
            <input type="password" size="20" formControlName="oldPass">
            <div *ngIf="errorForm" class="zeta-error">
                <div *ngIf="resetForm.controls['oldPass'].errors">
                    <small *ngIf="resetForm.controls['oldPass'].errors.required" class="p-invalid">(*) Old Password is required.</small>
                </div>
            </div>
        </div>
        <div class="p-col-12 zeta-input">
            <span>New Password</span>
            <input type="password" size="20" maxlength="13" formControlName="newPass">
            <div *ngIf="errorForm" class="zeta-error">
                <div *ngIf="resetForm.controls['newPass'].errors">
                    <small *ngIf="resetForm.controls['newPass'].errors.required" class="p-invalid">(*) New Password is required.</small>
                    <small *ngIf="resetForm.controls['newPass'].errors.minlength" class="p-invalid">(*) New Password needs at least 8 characters.</small>
                </div>
            </div>
        </div>
        <div class="p-col-12 zeta-input">
            <span>Re-type Password</span>
            <input type="password" size="20" maxlength="13" formControlName="repPass">
            <div *ngIf="errorForm" class="zeta-error">
                <div *ngIf="resetForm.controls['repPass'].errors">
                    <small *ngIf="resetForm.controls['repPass'].errors.required" class="p-invalid">(*) New Password is required.</small>
                    <small *ngIf="resetForm.controls['repPass'].errors.minlength" class="p-invalid">(*) New Password needs at least 8 characters.</small>
                </div>                
                <small *ngIf="resetForm.controls['newPass'].value!=resetForm.controls['repPass'].value" class="p-invalid">(*) New Password and Re-type Password doesn't match.</small>
            </div>            
        </div>
    </div>
    <p-footer>
        <button pButton  style="font-size:12px;" type="button" icon="pi pi-times" (click)="closeChangePassword()" label="Cancel" class="ui-button-secondary"></button>
        <i class="zeta-space"></i>
        <button style="font-size:12px;" pButton type="submit" icon="pi pi-save" label="Reset"></button>
    </p-footer>
    </form>
</p-dialog>