<div class="body-centered">
    <div class="body-form" *ngIf="!isLoading">
        <form [formGroup]="forgotForm" (ngSubmit)="forgotPassword()">
            <div class="form-group text-center">
                <div class="form-title">
                    Forgotten Password    
                </div>
                <div class="form-subtitle">
                    Forgotten your password?, Enter your email address below to begin the reset process.
                </div>
            </div>
            <div class="form-group">
                <input type="email" formControlName="email" class="form-control" name="email" placeholder="Email" autofocus>
                <div *ngIf="errorForm && forgotForm.controls['email'].errors" class="zeta-error">
                    <small *ngIf="forgotForm.controls['email'].errors.required" class="p-invalid">(*) Email is required.</small>                            
                </div>                
            </div>            
            <div class="form-group">
                <button pButton type="button" label="Reset Password" class="btn btn-block" type="submit"></button>
            </div>
            <div class="form-link">
                <a role="button" [routerLink]="routeLogin">Do you want log in?</a>
            </div>
        </form>
    </div>
    <div class="body-loading" *ngIf="isLoading">
    </div>
</div>

