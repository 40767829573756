<div class="messageContainerStyle">
  <!--ToolTip-->
  <cometchat-message-actions
    class="tool"
    [ngClass]="{
      toolGroup: messageDetails?.receiverType == GROUP
    }"
    [messageDetails]="messageDetails"
    (actionGenerated)="actionHandler($event)"
    *ngIf="showToolTip"
  ></cometchat-message-actions>
  <div class="messageWrapperStyle">
    <!--Avatar-->
    <div class="messageThumbnailStyle" *ngIf="avatarIfGroup">
      <cometchat-avatar
        [item]="messageDetails?.sender"
        [enableUserStatus]="false"
        class="avatarStyle"
      ></cometchat-avatar>
    </div>
    <!--Avatar Ends-->
    <div class="messageDetailStyle">
      <!--Name-->
      <div class="nameWrapperStyle">
        <span class="nameStyle">
          {{ name }}
        </span>
      </div>
      <!--Name End-->
      <div class="messageVideoContainer">
        <div class="messageVideoWrapperStyle">
          <video controls>
            <source [src]="videoUrl" />
          </video>
        </div>
      </div>
      <cometchat-message-reactions
        *ngIf="checkReaction"
        [messageDetails]="messageDetails"
        [loggedInUser]="loggedInUser"
        (actionGenerated)="actionHandler($event)"
      ></cometchat-message-reactions>
      <div class="messageInfoWrapperStyle">
        <cometchat-read-receipt
          [messageDetails]="messageDetails"
          [displayReadReciept]="false"
        ></cometchat-read-receipt>
        <!--ReplyCount-->
        <cometchat-threaded-message-reply-count
          *ngIf="showReplyCount"
          [messageDetails]="messageDetails"
          (actionGenerated)="actionHandler($event)"
        >
        </cometchat-threaded-message-reply-count>
      </div>
    </div>
  </div>
</div>
