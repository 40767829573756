<div class="body-centered">
    <div class="body-form" *ngIf="!isLoading">
        <form [formGroup]="resetForm" (ngSubmit)="resetPassword()">
            <div class="form-group text-center">
                <div class="form-title">
                    Reset Password    
                </div>
                <div class="form-subtitle">
                    Please fill and submit the form for reset your password.
                </div>
            </div>
            <div class="form-group">
                <input type="password" formControlName="password" minlength="8" class="form-control" name="password" placeholder="Password" autofocus>
                <div *ngIf="errorForm && resetForm.controls['password'].errors" class="zeta-error">
                    <small *ngIf="resetForm.controls['password'].errors.required" class="p-invalid">(*) Password is required.</small>                            
                    <small *ngIf="resetForm.controls['password'].errors.minlength" class="p-invalid">(*) Password needs at least 8 characters.</small>
                </div>    
            </div>  
            <div class="form-group">
                <input type="password" formControlName="repassword" class="form-control" name="repassword" placeholder="Re-type Password" autofocus>
                <div *ngIf="errorForm && resetForm.controls['repassword'].errors" class="zeta-error">
                    <small *ngIf="resetForm.controls['repassword'].errors.required" class="p-invalid">(*) Re-type password is required.</small>
                </div>    
            </div>           
            <div class="form-group">
                <button pButton type="button" label="Update Password" class="btn btn-block" type="submit"></button>
            </div>
        </form>
    </div>
    <div class="body-loading" *ngIf="isLoading">
    </div>
</div>

