import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { StorageService } from "src/app/services/security/storage.service"
import { AuthService } from "src/app/services/security/auth.service"
import { MessageService } from 'primeng/api';
import { CometchatService } from 'src/app/services/chat/cometchat.service';
import { Session } from 'src/app/models/session';
import { CometChat } from '@cometchat-pro/chat';
import { EnumGeneric } from 'src/app/models/enums';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  isLoading = false;
  loginForm: FormGroup;
  errorForm = false;
  routeForgot: string;
  private sub: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private messageService: MessageService,
    private chatService: CometchatService,
  ) {
    this.routeForgot = "/forgot";
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    })
  }

  public submitLogin(): void {
    this.isLoading = false;
    this.errorForm = false;
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.sub = this.authService.login(this.loginForm.value).subscribe(
        async data => {
          this.isLoading = false;
          let session = data as Session;
          console.log('zubair', data);
          if (session.reset == 0) {
            this.storageService.initSession(JSON.stringify(data));
            await this.initialize();
            await this.initChat(this.loginForm.controls['username'].value);
            this.router.navigate(['home']);
          } else {
            this.router.navigate(['loginpass']);
          }
        },
        err => {
          console.log(err);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message });
          this.loginForm.controls["password"].setValue("");
          this.isLoading = false;
        }
      )
    } else {
      this.errorForm = true;
    }
  }
  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  async initialize() {
    const chatSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(environment.COMETCHAT_API_REGION)
      .build();

    await CometChat.init(environment.COMETCHAT_API_ID, chatSetting).then(
      () => {
        console.log("CometChat Initialization completed successfully");

      },
      (error) => {
        console.log("CometChat Initialization failed with error:", error);
      }
    );
  }

  initChat(userName: String) {
    var uName = userName.replace("-user", "").replace("-admin", "").replace("-physician", "");
    var uKey = this.storageService.getSessionAttribute(EnumGeneric.SESSION_APICHAT);
    this.loginUser(uName, uKey);
  }

  loginUser(userName: String, userKey: String) {
    CometChat.login(userName, environment.COMETCHAT_API_KEY).then(
      (user) => {
        console.log("CometChat Login Successful");
      },
      (error) => {
        console.log("Login failed with exception:", { error });
      }
    );
  }
}

