<div class="detailStyle">
  <div class="headerStyle">
    <div class="headerCloseStyle" (click)="closeThreadView()"></div>
    <h4 class="headerTitleStyle">{{ DETAILS }}</h4>
  </div>
  <div class="detailPaneStyle">
    <!-- MEMBER SECTION BELOW-->
    <div class="sectionStyle">
      <h6 class="sectionHeaderStyle">Members</h6>
      <div class="sectionContentStyle">
        <div class="contentItemStyle">
          <div class="itemLinkStyle" (click)="toggleViewMember()">
            {{ VIEW_MEMBERS }}
          </div>
        </div>
      </div>

      <div class="sectionContentStyle" *ngIf="currentMemberScope == ADMIN">
        <div class="contentItemStyle">
          <div class="itemLinkStyle" (click)="toggleAddMemberView(true)">
            {{ ADD_MEMBERS }}
          </div>
        </div>
      </div>

      <div
        class="sectionContentStyle"
        *ngIf="currentMemberScope == ADMIN || currentMemberScope == MODERATOR"
      >
        <div class="contentItemStyle">
          <div class="itemLinkStyle" (click)="toggleBanMember()">
            {{ BANNED_MEMBERS }}
          </div>
        </div>
      </div>
    </div>
    <!-- MEMBER SECTION ABOVE-->

    <!-- OPTION SECTION BELOW-->
    <div class="sectionStyle">
      <h6 class="sectionHeaderStyle">{{ OPTIONS }}</h6>
      <div class="sectionContentStyle">
        <div class="contentItemStyle">
          <div class="itemLinkStyle" (click)="leaveGroup()">
            {{ LEAVE_GROUP }}
          </div>
        </div>
      </div>

      <div class="sectionContentStyle" *ngIf="currentMemberScope == ADMIN">
        <div class="contentItemStyle">
          <div
            class="itemLinkStyle itemDeleteLinkStyle"
            (click)="deleteGroup()"
          >
            {{ DELETE_AND_EXIT }}
          </div>
        </div>
      </div>
      <!-- SHARED MEDIA SECTION BELOW-->
      <div class="sharedMedia">
        <cometchat-shared-media
          [item]="item"
          [type]="type"
        ></cometchat-shared-media>
      </div>
      <!-- SHARED MEDIA SECTION ABOVE-->
    </div>
    <!-- OPTION SECTION ABOVE-->
  </div>

  <!-- VIEW MEMBERS COMPONENT -->
  <cometchat-view-group-member-list
    *ngIf="openViewMember"
    [item]="item"
    [type]="type"
    [memberList]="memberList"
    [loggedInUser]="loggedInUser"
    (actionGenerated)="actionHandler($event)"
  ></cometchat-view-group-member-list>

  <!-- ADD MEMBERS COMPONENT -->
  <cometchat-add-group-member-list
    *ngIf="openAddMemberView"
    [item]="item"
    [type]="type"
    [friendsOnly]="false"
    [memberList]="memberList"
    [bannedMemberList]="bannedMemberList"
    (actionGenerated)="actionHandler($event)"
  ></cometchat-add-group-member-list>

  <!-- BANNED MEMBERS COMPONENT -->
  <cometchat-ban-group-member-list-item
    *ngIf="openBanMember"
    [item]="item"
    [bannedMemberList]="bannedMemberList"
    (actionGenerated)="actionHandler($event)"
  ></cometchat-ban-group-member-list-item>
</div>
