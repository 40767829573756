<div class="wrapperStyle">
  <div class="headerStyle">
    <div class="headerWrapperStyle">
      <div class="headerDetailStyle">
        <h6 class="headerTitleStyle">{{ THREAD }}</h6>
        <span class="headerNameStyle">{{ item.name }}</span>
      </div>
      <div class="headerCloseStyle" (click)="closeThread()"></div>
    </div>
  </div>

  <div class="messageContainerStyle">
    <div class="parentMessageStyle">
      <div [ngSwitch]="typeOfMessage">
        <!--CASE FOR STICKER -->
        <div *ngSwitchCase="'extension_sticker'">
          <cometchat-sender-sticker-message-bubble
            *ngIf="parentMessage?.sender?.uid === loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          >
          </cometchat-sender-sticker-message-bubble>
          <cometchat-receiver-sticker-message-bubble
            *ngIf="parentMessage?.sender?.uid !== loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          >
          </cometchat-receiver-sticker-message-bubble>
        </div>
        <!--CASE FOR STICKER ENDS -->

        <!--CASE FOR POLL -->
        <div *ngSwitchCase="'extension_poll'">
          <cometchat-sender-poll-message-bubble
            *ngIf="parentMessage?.sender?.uid === loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          >
          </cometchat-sender-poll-message-bubble>
          <cometchat-receiver-poll-message-bubble
            *ngIf="parentMessage?.sender?.uid !== loggedInUser?.uid"
            [loggedInUserUid]="loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          >
          </cometchat-receiver-poll-message-bubble>
        </div>
        <!--CASE FOR POLL ENDS -->

        <!-- CASE FOR TEXT -->
        <div *ngSwitchCase="MESSAGE_TYPE_TEXT">
          <cometchat-receiver-text-message-bubble
            *ngIf="parentMessage?.sender?.uid !== loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-receiver-text-message-bubble>

          <cometchat-sender-text-message-bubble
            *ngIf="parentMessage?.sender?.uid === loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-sender-text-message-bubble>
        </div>
        <!--CASE FOR TEXT ENDS -->

        <!-- CASE FOR FILE -->
        <div *ngSwitchCase="MESSAGE_TYPE_FILE">
          <cometchat-receiver-file-message-bubble
            *ngIf="parentMessage?.sender?.uid !== loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-receiver-file-message-bubble>

          <cometchat-sender-file-message-bubble
            *ngIf="parentMessage?.sender?.uid === loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-sender-file-message-bubble>
        </div>
        <!--CASE FOR FILE ENDS -->

        <!-- CASE FOR IMAGE -->
        <div *ngSwitchCase="MESSAGE_TYPE_IMAGE">
          <cometchat-receiver-image-message-bubble
            *ngIf="parentMessage?.sender?.uid !== loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-receiver-image-message-bubble>

          <cometchat-sender-image-message-bubble
            *ngIf="parentMessage?.sender?.uid === loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-sender-image-message-bubble>
        </div>
        <!--CASE FOR IMAGE ENDS -->

        <!-- CASE FOR VIDEO -->
        <div *ngSwitchCase="MESSAGE_TYPE_VIDEO">
          <cometchat-receiver-video-message-bubble
            *ngIf="parentMessage?.sender?.uid !== loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-receiver-video-message-bubble>

          <cometchat-sender-video-message-bubble
            *ngIf="parentMessage?.sender?.uid === loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-sender-video-message-bubble>
        </div>
        <!--CASE FOR VIDEO ENDS -->

        <!-- CASE FOR AUDIO -->
        <div *ngSwitchCase="MESSAGE_TYPE_AUDIO">
          <cometchat-receiver-audio-message-bubble
            *ngIf="parentMessage?.sender?.uid !== loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-receiver-audio-message-bubble>

          <cometchat-sender-audio-message-bubble
            *ngIf="parentMessage?.sender?.uid === loggedInUser?.uid"
            [messageDetails]="parentMessage"
            [showToolTip]="false"
            [showReplyCount]="false"
            [loggedInUser]="loggedInUser"
            (actionGenerated)="actionHandler($event)"
          ></cometchat-sender-audio-message-bubble>
        </div>
        <!--CASE FOR AUDIO ENDS -->
      </div>
    </div>

    <div class="messageSeparatorStyle">
      <span class="messageReplyStyle" *ngIf="replyCount > 0">
        {{ getReplyCountText(replyCount) }}</span
      >
      <hr />
    </div>
    <div
      class="messageWindowStyle"
      (scroll)="handleScroll($event)"
      #messageWindow
      [scrollTop]="scrollVariable"
    >
      <cometchat-message-list
        [item]="item"
        [type]="type"
        [parentMessageId]="parentMessage?.id"
        [messages]="messageList"
        [reachedTopOfConversation]="reachedTopOfConversation"
        (actionGenerated)="actionHandler($event)"
      ></cometchat-message-list>
    </div>

    <cometchat-message-composer
      [item]="item"
      [type]="type"
      [parentMessageId]="parentMessage?.id"
      [replyPreview]="replyPreview"
      [messageToBeEdited]="messageToBeEdited"
      [messageToReact]="messageToReact"
      (actionGenerated)="actionHandler($event)"
    ></cometchat-message-composer>
  </div>
</div>
