import { Component } from '@angular/core';
import { StorageService } from "src/app/services/security/storage.service"
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})
export class AppComponent {
  isLogin=false;
  
  constructor(private storageService:StorageService) { 
    
  }
  ngOnInit() {    
    this.isLogin=false;
    setTimeout(() => {
      this.storageService.change.subscribe(isLogin => {
        this.isLogin = isLogin;
      });
    });
  }

}
