import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/security/auth.service'
import { StorageService } from 'src/app/services/security/storage.service'
import { MenuService } from 'src/app/services/util/menu.service'
import { EnumGeneric } from 'src/app/models/enums'

@Injectable({
  providedIn: 'root'
})
export class CanActivateRouteGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private menuService: MenuService
    ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      //console.log(state.url);
      //var aux=this.getSessionStatus(state.url)
      //console.log(aux);
      return this.getSessionStatus(state.url);
      //return true;
      
  }

  getSessionStatus(route:string){    
    if(this.storageService.existSession()){
      this.storageService.updateLogin(true);
      var profile=this.storageService.getSessionAttribute(EnumGeneric.SESSION_PROFILE);
      if(this.menuService.routeValidation(profile, route)){                
        return true;
      }else{
        return false;
      }
    }else{
      this.storageService.closeSession();
      this.router.navigate(['/login']);
      return false;
    }
  }
}
