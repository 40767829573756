<div class="body-centered">
    <div class="body-form" *ngIf="!isLoading">
        <form [formGroup]="loginForm" (ngSubmit)="submitLogin()">
            <div class="form-group text-center">
                <i class="body-logo"></i>
            </div>
            <div class="form-group">
                <input type="username" formControlName="username" class="form-control" name="username" placeholder="Username" autofocus>
                <div *ngIf="errorForm && loginForm.controls['username'].errors" class="zeta-error">
                    <small *ngIf="loginForm.controls['username'].errors.required" class="p-invalid">(*) Username is required.</small>                            
                </div>                
            </div>
            <div class="form-group">
                <input type="password" formControlName="password" class="form-control" name="password" placeholder="Password" autofocus>
                <div *ngIf="errorForm && loginForm.controls['password'].errors" class="zeta-error">
                    <small *ngIf="loginForm.controls['password'].errors.required" class="p-invalid">(*) Password is required.</small>                            
                </div>                
            </div>                        
            <div class="form-group">
                <button pButton type="button" label="Sign In" class="btn btn-block" type="submit"></button>
            </div>
            <div class="form-link">
                <a role="button" [routerLink]="routeForgot">Forgot your password</a>
            </div>
        </form>
    </div>
    <div class="body-loading" *ngIf="isLoading">
    </div>
</div>



