import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Form } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  public login(fieldForm: Form) {
    return this.http.post(environment.API_URL + '/auth/login', fieldForm);
  }

  public logout() {
    let fieldForm = {}
    return this.http.post(environment.API_URL + '/auth/logout', fieldForm, { responseType: 'text' });
  }

  public resetPassword(userid, userpass) {
    var fieldForm = { "userid": userid, "userpass": userpass }
    return this.http.put(environment.API_URL + '/auth/reset', fieldForm);
  }

  public updatePassword(fieldForm: Form) {
    return this.http.put(environment.API_URL + '/auth/password', fieldForm);
  }

  public updateUserPassword(fieldForm: Form) {
    return this.http.put(environment.API_URL + '/auth/change/user/password', fieldForm);
  }

  public changePassword(fieldForm: Form) {
    return this.http.post(environment.API_URL + '/auth/password/1', fieldForm);
  }

  public unique(username: string) {
    return this.http.get<any>(environment.API_URL + '/auth/unique/' + username);
  }

  public forgotTokenGenerate(fieldForm: Form) {
    return this.http.post(environment.API_URL + '/auth/forgot/generate', fieldForm);
  }

  public forgotTokenVerify(token: string) {
    return this.http.get<any>(environment.API_URL + '/auth/forgot/verify/' + token);
  }

  public forgotResetPassword(fieldForm: Form) {
    return this.http.put(environment.API_URL + '/auth/forgot/password', fieldForm);
  }

  public chatToken(token: string, id: number) {
    var data: any;
    data = { "token": token };
    return this.http.post(environment.API_URL + '/auth/token/' + id, data, { responseType: 'text' });
  }

}
