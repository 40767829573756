<div class="chatComposerStyle">
  <div
    class="editPreviewContainerStyle"
    *ngIf="openEditMessageWindow"
    [@slideInOut]
  >
    <div class="previewHeadingStyle">
      <div class="previewTextStyle">{{ EDIT_MESSAGE }}</div>
      <span class="previewCloseStyle" (click)="closeEditPreview()"></span>
    </div>
    <div>{{ this.messageToBeEdited?.data?.text }}</div>
  </div>
  <cometchat-smart-reply-preview
    [replyPreview]="replyPreview"
    (actionGenerated)="actionHandler($event)"
  ></cometchat-smart-reply-preview>
  <!--
  <cometchat-sticker-keyboard
    (actionGenerated)="actionHandler($event)"
    *ngIf="stickerViewer"
  ></cometchat-sticker-keyboard>
  -->
  <div class="blockedUserPopup" *ngIf="userBlocked">
    <p>You have blocked this contact</p>
    <p>
      <span
        >To start conversations, click on the icon at the top right corner and
        unblock the user</span
      >
    </p>
  </div>
  <div class="composerInputStyle">
    <div class="inputInnerStyle" tabindex="-1">
      <input
        class="messageInputStyle"
        contenteditable="true"
        [placeholder]="ENTER_YOUR_MESSAGE_HERE"
        dir="ltr"
        (input)="changeHandler($event)"
        (blur)="endTyping()"
        (keyup)="sendMessageOnEnter($event)"
        [value]="messageInput"
        #message
        [ngClass]="{
          messageInputDisabledStyle: userBlocked
        }"
      />      
      <div class="inputStickyStyle">
        <div class="stickyAttachmentStyle">
          <!--All file upload options starts here-->
          <div class="attachmentIconStyle" (click)="toggleFilePicker()">
            <span>&nbsp;</span>
          </div>

          <span
            [title]="ATTACH_VIDEO"
            (click)="getVideo()"
            class="fileItemStyle"
            id="video"
            [@FadeInFadeOut]="checkAnimatedState"
          >
            <input
              type="file"
              accept="video/*"
              #videoPicker
              (change)="onVideoChange($event)"
            />
          </span>
          <span
            [title]="ATTACH_AUDIO"
            (click)="getAudio()"
            class="fileItemStyle"
            id="audio"
            [@FadeInFadeOut]="checkAnimatedState"
          >
            <input
              type="file"
              accept="audio/*"
              #audioPicker
              (change)="onAudChange($event)"
            />
          </span>
          <span
            [title]="ATTACH_IMAGE"
            (click)="getImage()"
            class="fileItemStyle"
            id="image"
            [@FadeInFadeOut]="checkAnimatedState"
          >
            <input
              type="file"
              accept="image/*"
              #imagePicker
              (change)="onImgChange($event)"
            />
          </span>
          <span
            [title]="ATTACH_FILE"
            (click)="getFile()"
            class="fileItemStyle"
            id="file"
            [@FadeInFadeOut]="checkAnimatedState"
          >
            <input
              type="file"
              accept="file/*"
              #filePicker
              (change)="onFileChange($event)"
            />
          </span>

          <!--
          <span
            *ngIf="!parentMessageId"
            title="Create Poll"
            class="fileItemStyle"
            id="poll"
            (click)="openCreatePollPreview()"
            [@FadeInFadeOut]="checkAnimatedState"
          >
          </span>
          -->
        </div>
        
        <div class="stickyButtonStyle">
          <!-- all buttons will come here
          <span
            class="stickerBtnStyle"
            title="Add Sticker"
            (click)="toggleStickerPicker()"
            >&nbsp;</span
          >
          <span
            class="emojiButtonStyle"
            [title]="ADD_EMOJI"
            (click)="toggleEmoji()"
          >
          </span>
          <emoji-mart
            (emojiSelect)="addEmoji($event)"
            [title]="PICK_YOUR_EMOJI"
            emoji="point_up"
            *ngIf="emojiToggled"
            [style]="emojiStyle(parentMessageId)"
          ></emoji-mart>
          <span
            class="reactionBtnStyle"
            id="reaction"
            *ngIf="enableReaction && !parentMessageId"
            (click)="sendReaction($event)"
          >
          </span>
          -->
          <span
            id="send"
            title="send message"
            *ngIf="enableSendButton"
            class="sendButtonStyle"
            (click)="sendTextMessage(message.value)"
          >
          </span>
          
        </div>
      </div>
    </div>
  </div>
  <!-- POLL component Below -->
  <cometchat-create-poll
    [item]="item"
    [type]="type"
    (actionGenerated)="actionHandler($event)"
    *ngIf="createPollView"
  ></cometchat-create-poll>
  <!-- POLL component Below -->
</div>

<!-- oninput onblur onkeydown to be inplemented -->
