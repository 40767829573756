<!--backdrop-->
<div>
  <cometchat-backdrop></cometchat-backdrop>

  <div class="modalWrapperStyle">
    <span class="modalCloseStyle" (click)="closeBanMemberModal()"></span>
    <div class="modalBodyStyle">
      <table class="modalTableStyle">
        <caption class="tableCaptionStyle">
          {{
            BANNED_MEMBERS
          }}
        </caption>
        <thead>
          <tr>
            <th>{{ NAME }}</th>
            <th class="roleColumnStyle">{{ SCOPE }}</th>
            <th class="actionColumnStyle">{{ UNBAN }}</th>
          </tr>
        </thead>
        <!--message container-->
        <caption class="contactMsgStyle" *ngIf="displayDecoratorMessage">
          <p class="contactMsgTxtStyle">
            {{ decoratorMessage }}
          </p>
        </caption>
        <tbody class="tableBodyStyle" (scroll)="handleScroll($event)">
          <!--banned members-->
          <cometchat-ban-group-member-list
            *ngFor="let member of bannedMemberList"
            [member]="member"
            [item]="item"
            (actionGenerated)="actionHandler($event)"
          >
          </cometchat-ban-group-member-list>
        </tbody>
      </table>
    </div>
  </div>
</div>
