<div class="messageContainerStyle">
  <cometchat-message-actions
    class="tool"
    [messageDetails]="messageDetails"
    (actionGenerated)="actionHandler($event)"
    *ngIf="showToolTip"
  ></cometchat-message-actions>
  <div class="messageWrapperStyle">
    <div class="messageImgWrapper" (click)="open()">
      <div *ngIf="imageLoader" class="imageLoaderStyle">&nbsp;</div>
      <img [src]="imageUrl" loading="lazy" />
    </div>
  </div>
  <cometchat-message-reactions
    *ngIf="checkReaction"
    [messageDetails]="messageDetails"
    [loggedInUser]="loggedInUser"
    (actionGenerated)="actionHandler($event)"
  ></cometchat-message-reactions>
  <div class="messageInfoWrapperStyle">
    <!--ReplyCount-->
    <cometchat-threaded-message-reply-count
      *ngIf="showReplyCount"
      [messageDetails]="messageDetails"
      (actionGenerated)="actionHandler($event)"
    >
    </cometchat-threaded-message-reply-count>
    <cometchat-read-receipt
      [messageDetails]="messageDetails"
    ></cometchat-read-receipt>
  </div>
</div>
