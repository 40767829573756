<!--Backdrop-->
<cometchat-backdrop [show]="open"></cometchat-backdrop>
<div class="imageWrapperStyle" (click)="close()">
  <img
    [src]="imageUrl"
    class="imgStyle"
    alt="Full Screen View"
    loading="lazy"
  />
</div>
