import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Validators, FormGroup, FormBuilder} from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/security/auth.service"
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit, OnDestroy {

  isLoading=false;
  forgotForm: FormGroup;
  errorForm=false;
  routeLogin:string;
  private sub:Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService:AuthService,
    private messageService:MessageService
  ) {
    this.routeLogin="/login";
  }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', Validators.required]
    })
  }

  public forgotPassword(): void {
    this.isLoading=false;
    this.errorForm=false;
    if(this.forgotForm.valid){
      this.isLoading=true;
      this.sub=this.authService.forgotTokenGenerate(this.forgotForm.value).subscribe(
        async data => {
          this.isLoading=false;
          this.messageService.add({severity:'info', summary:'Success Message', detail:'An mail with the instructions to reset your password has been sent to your inbox.'});
          this.router.navigate(['login']);
        },
        err => {
          console.log(err);
          this.messageService.add({severity:'error', summary:'Error', detail:err.error.message});
          this.forgotForm.controls["email"].setValue("");
          this.isLoading=false;
        }
      );
    }else{
      this.errorForm=true;
    }
  }
  ngOnDestroy() {
    if(this.sub){
      this.sub.unsubscribe();
    }
  }

}
