<div class="chatListStyle">
  <!--Message Container-->
  <div class="decoratorMessageStyle">
    <p class="decoratorMessageTxtStyle">
      {{ decoratorMessage }}
    </p>
  </div>
  <div class="listWrapperStyle">
    <!--message-->
    <div *ngFor="let msg of messages; let i = index">
      <div class="messageDateContainerStyle" *ngIf="i === 0">
        <span class="messageDateStyle">{{
          msg?.sentAt * 1000 | date: "dd/MM/yyyy"
        }}</span>
      </div>
      <div
        class="messageDateContainerStyle"
        *ngIf="
          i > 0 && isDateDifferent(messages[i - 1]?.sentAt, messages[i]?.sentAt)
        "
      >
        <span class="messageDateStyle">{{
          msg?.sentAt * 1000 | date: "dd/MM/yyyy"
        }}</span>
      </div>
      <!--CASE FOR CALL MESSAGES-->
      <div *ngIf="msg?.category == CATEGORY_CALL">
        <cometchat-action-message-bubble
          [messageDetails]="msg"
          [loggedInUserUid]="loggedInUser?.uid"
        ></cometchat-action-message-bubble>
      </div>
      <!--CASE FOR CALL MESSAGES ENDS-->
      <!-- CASE FOR DELETED MESSAGES -->
      <div *ngIf="msg?.deletedAt; else elseBlock">
        <cometchat-delete-message-bubble
          [messageDetails]="msg"
          [loggedInUser]="loggedInUser"
        ></cometchat-delete-message-bubble>
      </div>
      <!-- CASE FOR DELETED MESSAGES ENDS -->

      <ng-template #elseBlock>
        <!-- NgSwitchCase for different Types Of Bubble -->
        <div [ngSwitch]="msg.type">
          <!-- CASE FOR TEXT -->
          <div *ngSwitchCase="MESSAGE_TYPE_TEXT">
            <cometchat-receiver-text-message-bubble
              *ngIf="msg.sender.uid !== loggedInUser.uid"
              [item]="item"
              [type]="type"
              [messageDetails]="msg"
              [loggedInUser]="loggedInUser"
              (actionGenerated)="actionHandler($event)"
            ></cometchat-receiver-text-message-bubble>
            <cometchat-sender-text-message-bubble
              *ngIf="msg.sender.uid === loggedInUser.uid"
              [messageDetails]="msg"
              [loggedInUser]="loggedInUser"
              (actionGenerated)="actionHandler($event)"
            ></cometchat-sender-text-message-bubble>
          </div>
          <!--CASE FOR TEXT ENDS -->
          <!--CASE FOR FILE-->
          <div *ngSwitchCase="MESSAGE_TYPE_FILE">
            <cometchat-sender-file-message-bubble
              *ngIf="msg.sender.uid === loggedInUser.uid"
              [messageDetails]="msg"
              [loggedInUser]="loggedInUser"
              (actionGenerated)="actionHandler($event)"
            ></cometchat-sender-file-message-bubble>
            <cometchat-receiver-file-message-bubble
              *ngIf="msg.sender.uid !== loggedInUser.uid"
              [messageDetails]="msg"
              [loggedInUser]="loggedInUser"
              (actionGenerated)="actionHandler($event)"
            ></cometchat-receiver-file-message-bubble>
          </div>
          <!--CASE FOR FILE ENDS-->
          <!--CASE FOR IMAGE -->
          <div *ngSwitchCase="MESSAGE_TYPE_IMAGE">
            <cometchat-sender-image-message-bubble
              *ngIf="msg.sender.uid === loggedInUser.uid"
              [messageDetails]="msg"
              [loggedInUser]="loggedInUser"
              (actionGenerated)="actionHandler($event)"
            ></cometchat-sender-image-message-bubble>
            <cometchat-receiver-image-message-bubble
              *ngIf="msg.sender.uid !== loggedInUser.uid"
              [messageDetails]="msg"
              [loggedInUser]="loggedInUser"
              (actionGenerated)="actionHandler($event)"
            >
            </cometchat-receiver-image-message-bubble>
          </div>
          <!--CASE FOR IMAGE  ENDS-->
          <!--CASE FOR VIDEO -->
          <div *ngSwitchCase="MESSAGE_TYPE_VIDEO">
            <div *ngIf="msg.category !== CATEGORY_CALL">
              <cometchat-sender-video-message-bubble
                *ngIf="msg.sender.uid === loggedInUser.uid"
                [messageDetails]="msg"
                [loggedInUser]="loggedInUser"
                (actionGenerated)="actionHandler($event)"
              >
              </cometchat-sender-video-message-bubble>
              <cometchat-receiver-video-message-bubble
                *ngIf="msg.sender.uid !== loggedInUser.uid"
                [messageDetails]="msg"
                [loggedInUser]="loggedInUser"
                (actionGenerated)="actionHandler($event)"
              >
              </cometchat-receiver-video-message-bubble>
            </div>
          </div>
          <!--CASE FOR VIDEO ENDS -->

          <!--CASE FOR AUDIO -->
          <div *ngSwitchCase="MESSAGE_TYPE_AUDIO">
            <div *ngIf="msg.category !== CATEGORY_CALL">
              <cometchat-sender-audio-message-bubble
                *ngIf="msg.sender.uid === loggedInUser.uid"
                [messageDetails]="msg"
                [loggedInUser]="loggedInUser"
                (actionGenerated)="actionHandler($event)"
              >
              </cometchat-sender-audio-message-bubble>
              <cometchat-receiver-audio-message-bubble
                *ngIf="msg.sender.uid !== loggedInUser.uid"
                [messageDetails]="msg"
                [loggedInUser]="loggedInUser"
                (actionGenerated)="actionHandler($event)"
              >
              </cometchat-receiver-audio-message-bubble>
            </div>
          </div>
          <!--CASE FOR AUDIO ENDS -->

          <!--CASE FOR Action Messages -->
          <div *ngSwitchCase="'groupMember'">
            <div class="actionMessageStyle">
              <p class="actionMessageTxtStyle">{{ msg?.message }}</p>
            </div>
          </div>
          <!--CASE FOR Action Messages -->
          <!--CASE FOR STICKER -->
          <div *ngSwitchCase="'extension_sticker'">
            <cometchat-sender-sticker-message-bubble
              *ngIf="msg.sender.uid === loggedInUser.uid"
              [messageDetails]="msg"
              [loggedInUser]="loggedInUser"
              (actionGenerated)="actionHandler($event)"
            >
            </cometchat-sender-sticker-message-bubble>
            <cometchat-receiver-sticker-message-bubble
              *ngIf="msg.sender.uid !== loggedInUser.uid"
              [messageDetails]="msg"
              [loggedInUser]="loggedInUser"
              (actionGenerated)="actionHandler($event)"
            >
            </cometchat-receiver-sticker-message-bubble>
          </div>
          <!--CASE FOR STICKER ENDS -->

          <!--CASE FOR POLLS -->
          <div *ngSwitchCase="'extension_poll'">
            <cometchat-sender-poll-message-bubble
              *ngIf="msg.sender.uid === loggedInUser.uid"
              [messageDetails]="msg"
              [loggedInUser]="loggedInUser"
              (actionGenerated)="actionHandler($event)"
            >
            </cometchat-sender-poll-message-bubble>
            <cometchat-receiver-poll-message-bubble
              *ngIf="msg.sender.uid !== loggedInUser.uid"
              [loggedInUserUid]="loggedInUser.uid"
              [messageDetails]="msg"
              [loggedInUser]="loggedInUser"
              (actionGenerated)="actionHandler($event)"
            >
            </cometchat-receiver-poll-message-bubble>
          </div>
          <!--CASE FOR  POLLS ENDS -->
        </div>
      </ng-template>
    </div>
  </div>
</div>
