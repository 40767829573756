import { Component, OnInit } from '@angular/core';
import { DataMenu, DataItem} from '../models/menu-data'
import { Menu, MenuItem } from '../models/menu'
import { Router } from "@angular/router";
import { StorageService } from 'src/app/services/security/storage.service'
import { EnumGeneric } from 'src/app/models/enums'
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menus:Menu[];
  profileName:string;
  constructor(
    private router: Router,
    private storageService:StorageService,
  ) {}

  ngOnInit() {
    let profile=this.storageService.getSessionAttribute(EnumGeneric.SESSION_PROFILE)
    this.buildMenu(parseInt(profile),parseInt(sessionStorage.getItem("menu") || "0"));
  }
  
  buildMenu(profile:number, selected:number){
    this.menus=[]
    var mnuLst=this.getMenuByProfile(profile).entries();
    for(var item of mnuLst){
      let nMenu=item[1];
      let iMenus=this.getItemByParentProfile(item[1].id, profile);
      nMenu.children=iMenus;
      nMenu.selected=false;
      if(nMenu.id==selected){
        nMenu.selected=true;
      }
      this.menus.push(nMenu);
    }
    return this.menus;
  }

  private getMenuByProfile(profile:number):Menu[]{
    return DataMenu.filter(function (ele) {
      return (ele.profile === profile);
    });
  }

  private getItemByParentProfile(parent:number, profile:number):MenuItem[]{
    return DataItem.filter(function (ele) {
      return (ele.parent === parent && ele.profile === profile);
    });
  }

  private setMenuSelected(id:number){
    var menus=[]
    for(var item of this.menus){
      item.selected=false
      if(item.id==id){
        sessionStorage.setItem("menu",item.id+"");
        item.selected=true;
      }
      menus.push(item);
    }
    return menus;
  }

  openMenu(id:number){
    var menus=[]
    for(var item of this.menus){
      item.open=false
      if(item.id==id){
        item.open=true;
      }
      menus.push(item);
    }
    this.menus=menus
  }

  closeMenus(){
    var menus=[]
    for(var item of this.menus){
      item.open=false
      menus.push(item);
    }
    return menus;
  }

  clickMenu(menu:Menu){    
    if(!menu.complex){
      if(menu.label=="Exit"){
        this.router.navigate(['/logout']);
      }else{
        this.storageService.clearDataFP();
        this.setMenuSelected(menu.id);        
        this.router.navigate([menu.link]);
      }
    }
  }
  clickItem(item:MenuItem){
    this.storageService.clearDataFP();
    this.setMenuSelected(item.parent);
    this.router.navigate([item.link]);
    //this.closeMenus();
  }
}
