<div class="messageContainerStyle">
  <!--ToolTip Component-->
  <cometchat-message-actions
    class="tool"
    *ngIf="showToolTip"
    [messageDetails]="messageDetails"
    (actionGenerated)="actionHandler($event)"
  ></cometchat-message-actions>

  <div class="messageWrapperStyle">
    <div class="messageTxtWrapperStyle" *ngIf="linkPreview === false">
      <p class="messageTxtStyle message__txt">
        {{ messageDetails.data.text }}
      </p>
    </div>
    <div class="messagePreviewContainerStyle" *ngIf="linkPreview">
      <div class="messagePreviewWrapperStyle">
        <div class="previewImageStyle">
          <img [src]="linkImage" loading="lazy" />
        </div>
        <div class="previewDataStyle">
          <div class="previewTitleStyle" *ngIf="linkTitle !== ''">
            <span>
              <!--titile-->
              {{ linkTitle }}
            </span>
          </div>
          <div class="previewDescStyle" *ngIf="linkDescription !== ''">
            <span>
              <!--descript-->
              {{ linkDescription }}
            </span>
          </div>
          <div class="previewTextStyle">
            <!--actual msg-->
            <a [href]="linkUrl" target="_blank"> {{ linkUrl }} </a>
          </div>
        </div>
        <div class="previewLinkStyle">
          <a [href]="linkUrl" target="_blank">
            {{ linkText }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <cometchat-message-reactions
    *ngIf="checkReaction"
    [messageDetails]="messageDetails"
    [loggedInUser]="loggedInUser"
    (actionGenerated)="actionHandler($event)"
  ></cometchat-message-reactions>
  <div class="messageInfoWrapperStyle">
    <!--ReplyCount component-->
    <cometchat-threaded-message-reply-count
      *ngIf="showReplyCount"
      [messageDetails]="messageDetails"
      (actionGenerated)="actionHandler($event)"
    >
    </cometchat-threaded-message-reply-count>
    <!--ReadReciept component-->
    <cometchat-read-receipt
      [messageDetails]="messageDetails"
    ></cometchat-read-receipt>
  </div>
</div>
