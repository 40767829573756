<tr class="tableRowStyle">
  <td>
    <div class="avatarStyle">
      <!--avatar-->
      <cometchat-avatar
        [item]="member"
        [userStatus]="member?.status"
      ></cometchat-avatar>
    </div>
    <div class="nameStyle">
      <!--name-->
      {{ member?.name }}
    </div>
  </td>
  <td class="roleStyle">
    <!--scope-->
    {{ member?.scope }}
  </td>
  <td class="actionStyle">
    <!--Unban-->
    <span (click)="unbanMember()">
      <!-- ban icon -->
      <img [src]="banIcon" loading="lazy" />
    </span>
  </td>
</tr>
